<script setup lang="ts">
import gsap from 'gsap'

const props = defineProps<{
  latestAnnouncement: any
}>()

const isAnnouncementLoading = ref(true)
const announcementText = ref('')
const hasAnnouncement = ref(false)
const announcementSlug = ref('')
const isAnimationRunning = ref(false)

const startAnnouncementAnimation = () => {
  const speed = 0.0175
  const border = document.querySelector('#announcements-border')
  const container = document.querySelector('#announcements-container')
  const span = container.querySelector('span')
  const { clientWidth } = span
  if (clientWidth < container.clientWidth) {
    return
  }
  const distanceToScroll = clientWidth - container.clientWidth * 0.9
  const duration = Math.abs(distanceToScroll * speed)
  const tl = gsap.timeline({ repeat: -1 })
  let delayWhenStop = 5
  if (window.innerWidth < 720) {
    delayWhenStop = 2
  }
  tl.to(span, {
    duration,
    x: -distanceToScroll,
    ease: 'none',
    delay: 2,
  })
    .to(span, {
      duration: 0.5,
      y: -40,
      delay: delayWhenStop,
    })
    .to(span, {
      duration: 0,
      x: 0,
      y: -40,
      opacity: 0,
    })
    .to(border, {
      duration: 0.3,
      rotate: 180,
    })
    .to(span, {
      duration: 0.5,
      y: 0,
    })
    .to(
      span,
      {
        duration: 0.3,
        opacity: 1,
      },
      '-=0.2',
    )
  isAnimationRunning.value = true
}

const adjustMainHeroHeight = () => {
  const windowWidth = window?.innerWidth
  const mainHero = document?.querySelectorAll('.main-hero')

  const getHeight = (offset) => `calc(calc(var(--vh, 1vh) * 100) - ${offset}px)`

  mainHero.forEach((element: HTMLElement) => {
    if (windowWidth < 430) {
      element.style.height = getHeight(295)
      element.style.transition = 'height 700ms cubic-bezier(0.4, 0, 0.2, 1)'
    } else if (windowWidth >= 430 && windowWidth < 768) {
      element.style.height = getHeight(345)
      element.style.transition = 'height 700ms cubic-bezier(0.4, 0, 0.2, 1)'
    }
  })
}

const isValidAnnouncementData = (data) => {
  const { announcements } = data || {}
  const { nodes } = announcements || {}
  return Array.isArray(nodes) && nodes?.length > 0
}
const handleAnnouncementData = (data) => {
  const isValidData = isValidAnnouncementData(data)

  if (!isValidData) {
    adjustMainHeroHeight()
    return
  }

  const announcement = data.announcements.nodes[0]
  const hasValidTitle = announcement.title.trim() !== ''

  if (!hasValidTitle) {
    adjustMainHeroHeight()
    return
  }

  hasAnnouncement.value = true
  announcementText.value = `${announcement.title}`
  announcementSlug.value = announcement.slug
}

watch(
  () => props.latestAnnouncement,
  (latestAnnouncement) => {
    isAnnouncementLoading.value = false
    nextTick(() => {
      handleAnnouncementData(latestAnnouncement)
      if (window === undefined) return
      startAnnouncementAnimation()
    })
  },
  { immediate: true },
)
</script>

<template>
  <NuxtLink
    :to="`/announcements/${announcementSlug}`"
    data-testid="announcement-bar"
    no-prefetch
  >
    <div
      class="bg-s-900 w-full transition-all duration-700"
      :class="{
        'border-p-500 h-10 border-t-2 pb-[10px] pt-2': hasAnnouncement,
        'h-0 p-0': !hasAnnouncement,
      }"
    >
      <div
        class="md:max-w-content-container mx-auto flex w-full px-[18px] transition-all duration-1000 md:px-4 lg:px-14 2xl:px-0"
        :class="{
          'opacity-1': hasAnnouncement,
          'opacity-0': !hasAnnouncement,
        }"
      >
        <div
          v-if="!isAnnouncementLoading"
          id="announcements-border"
          class="bg-p-500 mr-[6px] w-[2px] transition-all duration-1000"
          :class="{
            block: hasAnnouncement,
            hidden: !hasAnnouncement,
          }"
        />
        <div
          id="announcements-container"
          class="relative h-5 w-full overflow-hidden pl-[6px] text-white transition-all duration-1000"
          :class="{
            block: hasAnnouncement,
            hidden: !hasAnnouncement,
          }"
        >
          <span class="absolute whitespace-nowrap font-semibold">
            {{ announcementText }}
          </span>
          <div
            v-if="!isAnnouncementLoading && isAnimationRunning"
            class="absolute -right-px hidden h-full w-16 md:block"
            style="background: linear-gradient(to left, #25262b, #0000)"
          />
        </div>
      </div>
    </div>
  </NuxtLink>
</template>
